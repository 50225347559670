:global(.MuiButton-root){
  &.btn {
    color: #1e293b;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;

    padding: 12px 24px 12px 24px;
    border-radius: 4px;

    transition-property: all;
    transition-timing-function: linear;
    transition-duration: 150ms;

    &.secondary{
      background-color: #9caeb7;;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.btn:hover {
    background-color: #9caeb733;
  }

  &.btn:active {
    color: white;
    background-color: #475569;
    outline: none;
  }

  &.btn:disabled {
    color: #475569;
    background-color: #94a3b8;
  }
}