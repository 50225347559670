:global(.MuiButton-root) {
  &.btn {
    padding: 12px 24px 12px 24px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

    transition-property: none;
    transition-timing-function: linear;
    transition-duration: 150ms;

    outline: 0;
    &:focus {
      outline: 0;
    }

    &.secondary {
      background-color: #9caeb7;
    }
  }

  &.textButton {
    color: white;
    background: transparent;
    box-shadow: none;
    border: 1px solid rgba(238, 238, 238, 0);
    &.btn:hover {
      background-color: rgba(238, 238, 238, 0.3);
      border: 1px solid rgba(238, 238, 238, 1);
      box-shadow: none;
    }
    &.secondary {
      background: transparent;
      color: #f4b860;
      &.btn:hover {
        background-color: rgba(244, 184, 96, 0.3);
        border: 1px solid #f4b860;
        box-shadow: none;
      }
    }
  }

  &.btn:hover {
    background-color: rgba(238, 238, 238, 0.8);
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  &.btn:active {
    color: white;
    background-color: rgba(238, 238, 238, 0.8);
    outline: none;
  }

  &.btn:disabled {
    color: #475569;
    background-color: #94a3b8;
  }

  &.gradient {
    color: white;
    background: rgb(19, 154, 67);
    background: linear-gradient(
      -90deg,
      rgba(19, 154, 67, 1) 0%,
      rgba(19, 123, 154, 1) 100%
    );
  }

  &.gradient:hover {
    background: white rgb(19, 154, 67, 0.8);
    background: white
      linear-gradient(
        -90deg,
        rgba(19, 154, 67, 0.8) 0%,
        rgba(19, 123, 154, 0.8) 100%
      );
  }

  &.marginBottom {
    margin-bottom: 10px;
  }
}
